'use strict';

var processInclude = require('base/util');

function pdSlider() {

    if ($('.pd-slider.slick-initialized').length) {
        $('.pd-slider').slick('unslick');
    }

    // Detect if page in in the editor
    if (window.location !== window.parent.location) {
        $(".experience-page").addClass('in-editor');

        $('.experience-component .carousel').each(function() {
            if(!$(this).hasClass('edit-mode')){
                $(this).find('.pd-slider').slick();
            }
        });
    } else {
        $('.experience-component .carousel').each(function() {
            $(this).find('.pd-slider').slick();
        });
    }
}

$(document).ready(function () {
    // processInclude(require('./experience/imageAndText'));
    // processInclude(require('./experience/featureFocus'));
    // processInclude(require('./experience/videoTile'));
    processInclude(require('./experience/spacer'));

    $('.pd-text-slider').slick({
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    // Detect if page in in the editor
    if (window.location !== window.parent.location) {
        $('.experience-page').addClass('in-editor');

        $('.experience-component .carousel').each(function (index) {
            if (!$(this).hasClass('edit-mode')) {
                $(this).find('.pd-slider').slick();
            }
        });
    } else {
        $('.pd-slider').slick();
    }

    pdSlider();

});

$(window).smartresize(function() {
    pdSlider();
});

